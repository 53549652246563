import { daysFromNow, formatDate, millisecsToDays } from '../../utils/helpers';
import ExplanationPopup from '@/components/ExplanationPopup/ExplanationPopup.vue';
import IconTextButton from '../IconTextButton';
import { userEvents } from '../../utils/constants';

export default {
  components: {
    ExplanationPopup,
    IconTextButton
  },
  data() {
    return {
      highlightDocument: null,
      pageSize: this.itemsPerPage,
      currentDocuments: this.documents.slice(0, this.itemsPerPage),
      activePage: 1
    };
  },
  props: {
    documents: {
      type: Array,
      default: () => [],
      required: true
    },
    openDocument: {
      type: Function,
      required: true
    },
    itemsPerPage: {
      type: Number,
      required: true
    }
  },
  methods: {
    toggleExplanation(e) {
      this.$store.dispatch('toggleExplanation');
      e.stopPropagation();
    },
    barLeft(document) {
      return (
        this.CHART_LINE_WIDTH_MULTIPLIER * daysFromNow(document.payoutEarly)
      );
    },
    barWidth(document) {
      const range =
        document.payoutLate - Math.max(document.payoutEarly, this.dateNow);
      const delta = document.payoutLate < this.dateNow ? 0 : range;
      let days = millisecsToDays(delta);
      // ensure a minimum width of 1 for the bars
      if (days < 1 && document.payoutLate > this.dateNow) {
        days = 1;
        if (document.payoutLate === this.dateMax) {
          // extend chart width to account for extra space added by this minimum
          this.HIGH_DATE_WIDTH_BUFFER = 1;
        }
      }
      return this.CHART_LINE_WIDTH_MULTIPLIER * days;
    },
    distanceFromNow(date) {
      const days = daysFromNow(date);
      if (days > 0) {
        return this.CHART_LINE_WIDTH_MULTIPLIER * days;
      } else {
        // "x days overdue" and "Anticipated today" placeholder
        return 25;
      }
    },
    distanceFromDateMax() {
      return (
        this.CHART_LINE_WIDTH_MULTIPLIER *
        (daysFromNow(this.dateMax) + this.HIGH_DATE_WIDTH_BUFFER)
      );
    },
    distanceFromDateMaxInitial() {
      return daysFromNow(this.dateMax) + this.HIGH_DATE_WIDTH_BUFFER;
    },
    isNotOverdue(payoutLate) {
      return payoutLate >= this.dateNow;
    },
    formatDate(date) {
      return formatDate(date);
    },
    setHighlightedDocument(doc) {
      this.highlightDocument = doc;
    },
    openDocumentOnClick(document) {
      this.$pendo.track(userEvents.document_gantt_click);
      this.openDocument(document);
    },
    availableBarWidth() {
      const totalWidthInPx = window.innerWidth;
      if (!this.$refs.bottom || !this.$refs.labels) {
        return 0;
      }
      // measure with sub-pixel precision
      const availableWidthInPx =
        parseFloat(getComputedStyle(this.$refs.bottom).width) -
        parseFloat(getComputedStyle(this.$refs.labels).width) -
        1; /* subtract 1px for ".gantt-wrapper" border-left width */
      // in vw - relative to 1% of the width of the viewport
      return (100 * availableWidthInPx) / totalWidthInPx;
    },
    setBarMinWidth(availableBarWidth) {
      // enlarge bar width to make use of more screen width
      const distanceFromDateMax = this.distanceFromDateMaxInitial();
      if (distanceFromDateMax < availableBarWidth) {
        this.CHART_LINE_WIDTH_MULTIPLIER =
          availableBarWidth / distanceFromDateMax;
        this.$forceUpdate();
      } else if (this.CHART_LINE_WIDTH_MULTIPLIER !== 1) {
        this.CHART_LINE_WIDTH_MULTIPLIER = 1;
        this.$forceUpdate();
      }
    },
    setBarLabels() {
      this.$nextTick(() => {
        // dynamically show bar labels based on available bar width
        this.$refs.documentsRef.forEach(line => {
          const bar = line.getElementsByClassName('document-line')[0];
          if (!bar) {
            return;
          }

          const barLabelEarly =
            bar.getElementsByClassName('day-to-pay-early')[0];
          const barLabelLate = bar.getElementsByClassName('day-to-pay-late')[0];

          if (typeof barLabelEarly === 'undefined') {
            return; // document is overdue and doesn't have a bar
          }

          if (
            bar.clientWidth <=
            barLabelEarly.clientWidth + barLabelLate.clientWidth
          ) {
            barLabelEarly.classList.add('hidden');

            if (bar.clientWidth <= barLabelLate.clientWidth) {
              barLabelLate.classList.add('hidden');
              barLabelEarly.classList.add('none');
            } else {
              barLabelLate.classList.remove('hidden');
              barLabelEarly.classList.remove('none');
            }
          } else if (barLabelLate.classList.contains('hidden')) {
            barLabelLate.classList.remove('hidden');
            barLabelEarly.classList.remove('none');
          } else {
            barLabelEarly.classList.remove('hidden');
          }
        });
      });
    },
    initBars() {
      // only when chart is visible
      const availableBarWidth = this.availableBarWidth();
      if (availableBarWidth > 0) {
        this.setBarMinWidth(availableBarWidth);
        this.setBarLabels();
      }
    },
    onPageChange(event) {
      const activePage = event.detail.newVal;
      const startIndex = (activePage - 1) * this.pageSize;
      this.activePage = activePage;
      this.currentDocuments = this.documents.slice(
        startIndex,
        startIndex + this.pageSize
      );
      this.$nextTick(this.initBars);
    },
    onPerPageChange(event) {
      this.pageSize = event.detail.per_page;
      this.activePage = 1;
      this.currentDocuments = this.documents.slice(0, this.pageSize);
      this.$nextTick(this.initBars);
    }
  },
  computed: {
    dateNow() {
      return Date.now();
    },
    dateMax() {
      return Math.max(
        ...this.currentDocuments.map(document => document.payoutLate),
        this.dateNow
      );
    },
    monthIncrements() {
      // only works for 11 months into the future
      const increments = [];
      const date = new Date();
      const dateMax = new Date(this.dateMax);
      const thisMonth = date.getMonth();
      const dateMaxMonth = dateMax.getMonth();
      const fullMonthIncrement = dateMax.getDate() >= date.getDate() ? 0 : 1;
      const months =
        (dateMaxMonth >= thisMonth
          ? dateMaxMonth - thisMonth
          : 12 - thisMonth + dateMaxMonth) - fullMonthIncrement;

      for (let i = 0; i < months; i++) {
        const increment = date.setMonth(date.getMonth() + 1);

        if (i === months - 1) {
          // prevent overlapping last increment and max dates
          const daysBetween = millisecsToDays(dateMax - increment);
          const incrementGapToDateMax = 5;
          if (daysBetween < incrementGapToDateMax) {
            break;
          }
        }

        increments.push(increment);
      }

      return increments;
    },
    isExplanationShown() {
      return this.$store.getters.isExplanationShown;
    },
    isPagerShown() {
      return this.documents.length > this.itemsPerPage;
    },
    totalPages() {
      return Math.ceil(this.documents.length / this.pageSize);
    },
    pendingInvoicesStr() {
      return t('Pending invoices');
    },
    todayStr() {
      return t('Today');
    }
  },
  created() {
    this.CHART_LINE_WIDTH_MULTIPLIER = 1;
    this.HIGH_DATE_WIDTH_BUFFER = 0;
  },
  mounted() {
    this.$nextTick(this.initBars);
    window.addEventListener('resize', this.initBars);
  },
  destroyed() {
    window.removeEventListener('resize', this.initBars);
  }
};
