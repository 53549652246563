var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"gantt-root"}},[_c('div',{attrs:{"id":"gantt-payment-prediction"}},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"info"},[_c('ExplanationPopup'),_c('span',{staticClass:"info-icon",on:{"click":_vm.toggleExplanation}},[_c('IconTextButton',{attrs:{"iconPath":"/images/info.svg"}})],1)],1)]),_c('div',{ref:"bottom",staticClass:"bottom",class:{ 'min-height': _vm.isExplanationShown }},[_c('div',{ref:"labels",staticClass:"gantt-rows labels"},[_c('div',{staticClass:"pendingInvoices axisLabel"},[_vm._v(_vm._s(_vm.pendingInvoicesStr))]),_vm._l((_vm.currentDocuments),function(document){return _c('div',{key:document.documentUUID,staticClass:"gantt-row",class:{
            highlighted: _vm.highlightDocument === document.invoiceId
          },on:{"mouseover":function($event){return _vm.setHighlightedDocument(document.invoiceId)},"mouseleave":function($event){return _vm.setHighlightedDocument(null)},"click":function($event){return _vm.openDocumentOnClick(document)}}},[_c('div',{staticClass:"gantt-row-label document"},[_c('div',{staticClass:"document-details",class:{ unopened: !document.opened }},[_c('div',{staticClass:"document-details-id"},[_vm._v(_vm._s(document.invoiceId))]),_c('div',{staticClass:"document-details-total"},[_vm._v(" "+_vm._s(_vm._f("money")(document.payableAmount,document.documentCurrencyCode))+" ")])])])])})],2),_c('div',{staticClass:"gantt-wrapper"},[_c('div',{staticClass:"chart-line chart-line-0",style:({
            width: _vm.distanceFromDateMax() + 'vw'
          })},[_c('span',{staticClass:"today"},[_c('span',{staticClass:"axisLabel"},[_c('span',[_vm._v(_vm._s(_vm.todayStr)+", "+_vm._s(_vm.formatDate(_vm.dateNow)))])])]),_vm._l((_vm.monthIncrements),function(increment){return _c('span',{key:increment,style:({
              'margin-left': _vm.distanceFromNow(increment) + 'vw'
            })},[_c('span',{staticClass:"axisLabel"},[_c('span',[_vm._v(_vm._s(_vm.formatDate(increment)))])])])}),_c('span',{staticClass:"lastDay",style:({
              'margin-left': _vm.distanceFromDateMax() + 'vw'
            })},[_c('span',{staticClass:"axisLabel"},[_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.dateMax)))])])])],2),_vm._l((_vm.monthIncrements),function(increment){return _c('div',{key:increment,staticClass:"dateSeparator",style:({
            'margin-left': _vm.distanceFromNow(increment) + 'vw'
          }),attrs:{"title":_vm.formatDate(increment)}})}),_c('div',{staticClass:"gantt-rows",style:({
            width: _vm.distanceFromDateMax() + 'vw'
          })},_vm._l((_vm.currentDocuments),function(document){return _c('div',{key:document.documentUUID,ref:"documentsRef",refInFor:true,staticClass:"gantt-row",class:{
              highlighted: _vm.highlightDocument === document.invoiceId
            },on:{"mouseover":function($event){return _vm.setHighlightedDocument(document.invoiceId)},"mouseleave":function($event){return _vm.setHighlightedDocument(null)}}},[(_vm.isNotOverdue(document.payoutLate))?_c('div',{staticClass:"gantt-row-value colored",style:({
                width: _vm.barWidth(document) + 'vw',
                'margin-left': _vm.barLeft(document) + 'vw'
              }),attrs:{"title":_vm._f("payoutRange")(document)}},[_c('div',{staticClass:"document-line",on:{"click":function($event){return _vm.openDocumentOnClick(document)}}},[_c('div',{staticClass:"document-timerange"}),_c('span',{staticClass:"day-to-pay-early"},[_vm._v(_vm._s(_vm._f("payoutEarlyDate")(document)))]),_c('span',{staticClass:"day-to-pay-late"},[_vm._v(_vm._s(_vm._f("payoutLateDate")(document)))])])]):_c('div',{staticClass:"gantt-row-value"},[_c('span',{staticClass:"days-to-pay",on:{"click":function($event){return _vm.openDocumentOnClick(document)}}},[_vm._v(_vm._s(_vm._f("payoutRange")(document)))])])])}),0)],2)]),(_vm.isPagerShown)?_c('ts-pager',{attrs:{"per-page":_vm.pageSize,"total-pages":_vm.totalPages,"active-page":_vm.activePage},on:{"page-change":_vm.onPageChange,"per-page-change":_vm.onPerPageChange}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }