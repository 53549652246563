import axios from 'axios';
import Lexico from '@tradeshift/lexico';
import { baseURL } from './utils/constants';
import { getLanguage } from './utils/getLanguage';
import * as Sentry from '@sentry/browser';
import i18n from '@tradeshift/i18n';
import Vue from 'vue';
import { Vue as VueIntegration } from '@sentry/integrations';
import { pendo } from '@tradeshift/pendo';
import router from './router';
import store from './store';
import App from './App.vue';
import loadPolyfills from '@open-wc/polyfills-loader';

loadPolyfills().then(() => {
  axios.defaults.baseURL = baseURL;

  const requireContext = require.context(
    './translations/js',
    false,
    /\.js$/,
    'lazy'
  );

  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
      dsn: 'https://107bae7da80043dcb21c8419065a5150@o12589.ingest.sentry.io/5354862',
      integrations: [new VueIntegration({ Vue, attachProps: true })],
      environment: process.env.NODE_ENV
    });
  }

  const language = getLanguage();
  Promise.all([
    Lexico.init([language], requireContext),
    i18n.importLocale(language)
  ])
    .then(([lexico]) => {
      window.t = lexico.t.bind(lexico);

      Vue.config.productionTip = false;

      Vue.prototype.$pendo = pendo;

      new Vue({
        router,
        store,
        render: h => h(App)
      }).$mount('#app');
    })
    .catch(error => {
      throw new Error(`Error loading translations: ${error.message}`);
    });
});
