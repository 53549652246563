import {
  addMissingUpdates,
  computePaymentTime,
  isPending,
  isRejected,
  isSettled,
  updateStatusUpdateDates
} from '@/utils/helpers';
import axios from 'axios';
import { targetEnvURL } from '@/utils/constants';

const defaultSupplierEnrichments = {
  cashPromotionDocument:
    "[%customer% is later replaced by JS, do not translate it]<h2>Get paid just 3-6 days after submitting your invoice</h2><p>%customer% is offering Tradeshift Cash to speed up payments like this. Some great promotional information.</p><button>Apply for cash</button><p class='promo-disclaimer'>Application is free. You'll receive an answer in 1-2 business days.</p>",
  cashPromotionList:
    '[%amount% is later replaced by JS, do not translate it]<p><strong>%amount%</strong> may be eligible for early payment through the Tradeshift Cash Program provided by %customer%</p><button class="promo button">Learn more</button>',
  customer: '?'
};

const stateObj = {
  token: null,
  companyName: null,
  invitationToken: null,
  trackingId: null,
  subscribed: false,
  responseCode: null,
  activateLink: `${targetEnvURL}activationFlow?token=`,
  suppliers: [],
  supplierDocuments: [],
  supplierEnrichments: defaultSupplierEnrichments,
  buyerCompanyName: null,
  sellerCompanyName: null,
  insights: null,
  features: null
};

const getters = {
  token: state => state.token,
  responseCode: state => state.responseCode,
  activateLink: state => state.activateLink,
  invitationToken: state => state.invitationToken,
  trackingId: state => state.trackingId,
  supplierEnrichments: state => state.supplierEnrichments,
  supplierDocuments: state => payload => {
    let docs = state.supplierDocuments;

    if (payload.pending) {
      docs = docs.filter(doc => doc.isPending === payload.pending);
    }
    if (payload.rejected) {
      docs = docs.filter(doc => doc.isRejected === payload.rejected);
    }
    if (payload.settled) {
      docs = docs.filter(doc => doc.isSettled === payload.settled);
    }
    if (payload.customFilter) {
      switch (payload.customFilter) {
        case 1: // only documents in the last 12 months
          const referenceDate = new Date();
          referenceDate.setFullYear(referenceDate.getFullYear() - 1);
          docs = docs.filter(doc => new Date(doc.issueDate) >= referenceDate);
          break;
      }
    }

    return docs;
  },
  insights: state => state.insights,
  features: state => state.features
};

const mutations = {
  setSupplierDocuments(state, payload) {
    let docs = payload;
    docs = docs.map(doc => {
      const latestUpdate = doc.statusUpdates[doc.statusUpdates.length - 1];
      let payoutEarly;
      let payoutLate;
      let payoutMid;

      if (isPending(doc.statusUpdates)) {
        payoutEarly = new Date(doc.payoutEarly).getTime();
        payoutLate = new Date(doc.payoutLate).getTime();
        payoutMid = (payoutLate - payoutEarly) / 2 + payoutEarly;
      }

      if (!localStorage.getItem(state.invitationToken)) {
        localStorage.setItem(state.invitationToken, '{}');
      }

      return {
        ...doc,
        statusUpdates: addMissingUpdates(
          doc.statusUpdates
            .map(statusUpdate => ({
              ...statusUpdate,
              issueDate: updateStatusUpdateDates(statusUpdate.issueDate)
            }))
            .sort((updateA, updateB) => updateA.issueDate - updateB.issueDate)
        ),
        payableAmount: parseFloat(doc.payableAmount),
        latestUpdate,
        isPending: isPending(doc.statusUpdates),
        isSettled: isSettled(doc.statusUpdates),
        isRejected: isRejected(doc.statusUpdates),
        paymentTime: computePaymentTime(doc.statusUpdates, doc.issueDate),
        payoutEarly,
        payoutLate,
        payoutMid,
        promoteCash: false,
        opened: JSON.parse(localStorage.getItem(state.invitationToken))[
          `${doc.invoiceId}/${latestUpdate.responseCode}`
        ]
      };
    });

    docs.sort((docA, docB) => {
      return new Date(docB.issueDate) - new Date(docA.issueDate);
    });

    state.supplierDocuments = docs;
  },
  setSupplierEnrichments(state) {
    if (state.companyName) {
      state.supplierEnrichments = {
        ...state.supplierEnrichments,
        ...{
          customer: state.companyName
        }
      };
    }
  },
  setSupplierSubscribed(state) {
    state.subscribed = true;
  },
  setSupplierToken(state, payload) {
    state.token = payload;
  },
  setSupplierInfo(state, payload) {
    state.companyName = payload.companyName;
    state.trackingId = payload.trackingId;
    state.invitationToken = payload.invitationToken;
    state.activateLink += payload.invitationToken;
  },
  setResponseCode(state, payload) {
    state.responseCode = payload;
  },
  setBuyerCompanyName(state, payload) {
    state.buyerCompanyName = payload;
  },
  setSellerCompanyName(state, payload) {
    state.sellerCompanyName = payload;
  },
  setTrackingId(state, payload) {
    state.trackingId = payload;
    state.activateLink += `&trackingId=${payload}`;
  },
  setInsights(state, payload) {
    state.insights = payload;
  },
  setFeatures(state, payload) {
    state.features = payload;
  },
  // temporary, until backend is able to send the value
  setPaymentTurnAroundInsightValue(state, payload) {
    state.insights.forEach(insight => {
      if (insight.cardName === 'PAYMENT_TURNAROUND') {
        insight.cardValue = payload;
      }
    });
  },
  // temporary, until backend is able to send the value
  setRejectionRateInsightValue(state, payload) {
    state.insights.forEach(insight => {
      if (insight.cardName === 'REJECTION_RATE') {
        insight.cardValue = payload;
      }
    });
  },
  // temporary, until backend is able to send the value
  setLinkInsightValue(state, payload) {
    state.insights.forEach(insight => {
      if (insight.cardName === 'LINK') {
        insight.cardValue = payload;
      }
    });
  },
  // temporary, until backend is able to send the value
  deleteCard(state, payload) {
    state.insights.forEach((insight, index) => {
      if (insight.cardName === payload) {
        state.insights.splice(index, 1);
      }
    });
  },
  setAsOpened(state, payload) {
    payload.opened = true;
    const organicUpdates = payload.statusUpdates.filter(
      update => update.issueDate !== null
    );
    const latestUpdate = organicUpdates[organicUpdates.length - 1];
    const allOpened = JSON.parse(localStorage.getItem(state.invitationToken));
    if (allOpened) {
      allOpened[`${payload.invoiceId}/${latestUpdate.responseCode}`] = true;
      localStorage.setItem(state.invitationToken, JSON.stringify(allOpened));
    }
  }
};

const actions = {
  setToken({ commit, state }, token) {
    commit('setSupplierToken', token);
  },
  getSupplier({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/engage/track`, {
          params: {
            token: state.token
          }
        })
        .then(
          response => {
            if (response && response.data) {
              const supplierInfo = {
                companyName: response.data.companyName,
                invitationToken: response.data.invitationToken
              };

              commit('setSupplierInfo', supplierInfo);
              commit('setSupplierDocuments', response.data.invoices);
              commit('setSupplierEnrichments');
              commit('setSupplierSubscribed');
            }
            resolve(response);
          },
          error => {
            console.log(error);
            if (
              error.response &&
              [401, 404, 500].includes(error.response.status)
            ) {
              commit('setResponseCode', error.response.status);
            }
            reject(error);
          }
        );
    });
  },
  getCards({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/engage/platform/cards`, {
          headers: {
            'X-Tradeshift-AO-Token': state.token
          }
        })
        .then(
          response => {
            if (response && response.data) {
              commit('setBuyerCompanyName', response.data.buyerCompanyName);
              commit('setSellerCompanyName', response.data.sellerCompanyName);
              commit('setTrackingId', response.data.trackingId);
              commit('setInsights', response.data.insights);
              commit('setFeatures', response.data.features);
            }
            resolve(response);
          },
          error => {
            console.log(error);
            reject(error);
          }
        );
    });
  },
  // temporary, until backend is able to send the value
  setPaymentTurnAroundInsightValue({ commit }, average) {
    commit('setPaymentTurnAroundInsightValue', average);
  },
  // temporary, until backend is able to send the value
  setRejectionRateInsightValue({ commit }, value) {
    commit('setRejectionRateInsightValue', value);
  },
  // temporary, until backend is able to send the value
  setLinkInsightValue({ commit }, value) {
    commit('setLinkInsightValue', value);
  },
  // temporary, until backend is able to send the value
  deleteCard({ commit }, value) {
    commit('deleteCard', value);
  },
  setAsOpened({ commit }, value) {
    commit('setAsOpened', value);
  }
};

export default {
  namespaced: true,
  state: stateObj,
  getters,
  mutations,
  actions
};
