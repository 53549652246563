import Vue from 'vue';
import VueRouter from 'vue-router';
import DocumentTracker from '../views/DocumentTracker';
import SupplierOverview from '../views/SupplierOverview';
import NotFound from '../views/Errors/NotFound.vue';

Vue.use(VueRouter);

const router = new VueRouter({
  base: `${process.env.VUE_APP_PUBLIC_PATH}`,
  mode: 'history',
  routes: [
    {
      path: '/supplier-overview/:token',
      name: 'SupplierOverview',
      component: SupplierOverview,
      props: true
    },
    {
      path: '/document-tracker/:token/:invoiceId(.*)',
      name: 'DocumentTracker',
      component: DocumentTracker,
      props: true
    },
    {
      path: '*',
      name: 'NotFound',
      component: NotFound
    }
  ]
});

export default router;
