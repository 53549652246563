const stateObj = {
  isExplanationShown: false,
  currentTab: null
};

const getters = {
  isExplanationShown: state => state.isExplanationShown,
  currentTab: state => state.currentTab
};

const actions = {
  toggleExplanation({ state }) {
    state.isExplanationShown = !state.isExplanationShown;
  },
  setCurrentTab({ state }, payload) {
    state.currentTab = payload;
  }
};

export default {
  namespaced: false,
  state: stateObj,
  getters,
  actions
};
