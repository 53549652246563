import { UBLStatus } from '@/utils/ublConstants';

export const isPending = updates => {
  const updatesWithIssueDate = updates.filter(update => update.issueDate);
  const latestUpdate = updatesWithIssueDate[updatesWithIssueDate.length - 1];

  return (
    !latestUpdate ||
    (latestUpdate.responseCode !== UBLStatus.BusinessPaid &&
      latestUpdate.responseCode !== UBLStatus.BusinessReject &&
      latestUpdate.responseCode !== UBLStatus.TechnicalReject)
  );
};

export const isSettled = updates => {
  const updatesWithIssueDate = updates.filter(update => update.issueDate);
  const latestUpdate = updatesWithIssueDate[updatesWithIssueDate.length - 1];

  return latestUpdate && latestUpdate.responseCode === UBLStatus.BusinessPaid;
};

export const isRejected = updates => {
  const updatesWithIssueDate = updates.filter(update => update.issueDate);
  const latestUpdate = updatesWithIssueDate[updatesWithIssueDate.length - 1];

  return (
    latestUpdate &&
    (latestUpdate.responseCode === UBLStatus.BusinessReject ||
      latestUpdate.responseCode === UBLStatus.TechnicalReject)
  );
};
