import '@tradeshift/elements.spinner';
import MenuItem from '@/components/InsightCards/MenuItem/MenuItem.vue';
import ItemDetails from '@/components/InsightCards/ItemDetails/ItemDetails.vue';
import { userEvents } from '../../utils/constants';

export default {
  data() {
    return {
      processing: false,
      selected: {
        name: null,
        item: null
      },
      isDialogOpen: false
    };
  },
  components: {
    MenuItem,
    ItemDetails
  },
  computed: {
    showInsights() {
      return this.insights?.length > 0;
    },
    isMenuShown() {
      return this.insights?.length > 1;
    },
    insights() {
      return this.$store.getters['DataHandler/insights'];
    },
    insightsStr() {
      return t('Insights');
    },
    loadingStr() {
      return t('Loading');
    }
  },
  methods: {
    setSelected(selectedItem, ignoreEvent) {
      if (selectedItem) {
        if (!ignoreEvent) {
          this.$pendo.track(userEvents.insight_open, {
            selectedCard: selectedItem.cardName
          });
        }
        this.selected = {
          name: selectedItem.cardName,
          item: selectedItem
        };
      }
    },
    openDialog(insight) {
      this.setSelected(insight);
      this.isDialogOpen = true;
    },
    closeDialog() {
      this.isDialogOpen = false;
    },
    init() {
      const trackingId = this.$store.getters['DataHandler/trackingId'];
      // Pendo auto generates IDs if trackingId is missing, but since we are taxed per new ID, we use a static string
      this.$pendo.initialize({
        visitor: {
          id: trackingId ? trackingId : userEvents.userId_not_available
        }
      });

      this.setSelected(this.insights?.at(0), true);
    }
  },
  mounted() {
    if (!this.insights) {
      this.processing = true;
      this.$store
        .dispatch('DataHandler/getCards')
        .then(this.init)
        .finally(() => {
          this.processing = false;
        });
    } else {
      this.init();
    }
  }
};
