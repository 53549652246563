import '@tradeshift/elements.board';

export default {
  props: {
    code: {
      type: Number
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  computed: {
    contactSupportLinkTitle() {
      return t('Contact support');
    },
    goBackTitle() {
      return t('Go back');
    }
  },
  methods: {
    back() {
      window.history.back();
    }
  }
};
