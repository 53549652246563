import Documents from '../../components/Documents/Documents.vue';
import Header from '../../components/Header/Header.vue';
import InsightCards from '../../components/InsightCards/InsightCards.vue';

export default {
  components: {
    Documents,
    Header,
    InsightCards
  }
};
