import Button from '../../Button/Button.vue';
import '@tradeshift/elements.tooltip';
import { userEvents } from '../../../utils/constants';
import { cardNameToTestClass } from '../../../utils/helpers';

export default {
  components: {
    Button
  },
  props: {
    cardName: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    info: {
      type: String,
      required: true
    },
    ctaLink: {
      type: String,
      required: true
    },
    isMoreInfoIconShown: {
      type: Boolean,
      required: true
    },
    actionButtonLabel: {
      type: String,
      required: false
    },
    mobile: Boolean
  },
  computed: {
    insightTooltipStr() {
      return t('Calculated based on historical data provided by {customer}.', {
        customer:
          this.$store.getters['DataHandler/supplierEnrichments'].customer
      });
    },
    testClass() {
      return cardNameToTestClass(this.cardName);
    }
  },
  methods: {
    ctaHandler() {
      switch (this.cardName) {
        case 'LINK':
          this.$pendo.track(userEvents.main_learn_more, {
            from: this.cardName
          });
          break;
        default:
          this.$pendo.track(userEvents.main_activate_click, {
            from: this.cardName
          });
      }
    }
  }
};
