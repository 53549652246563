export const cardNameToTitle = cardName => {
  switch (cardName) {
    case 'PAYMENT_TURNAROUND':
      return t('Unpaid invoices');
    case 'REJECTION_RATE':
      return t('Rejection rate');
    case 'GO_LIVE_DATE':
      return t('Registration Deadline');
    case 'LINK':
      return t('Time spent on manual invoicing');
    default:
      return cardName;
  }
};
