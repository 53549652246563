export default {
  props: {
    document: {
      type: Object
    }
  },
  computed: {
    features() {
      return this.$store.getters['DataHandler/features'];
    },
    showUpcomingPayments() {
      return this.features?.find(
        feature => feature.cardName === 'UPCOMING_PAYMENTS'
      );
    },
    documentDetailsStr() {
      return t('Document details');
    },
    invoiceNoStr() {
      return t('Invoice#');
    },
    totalStr() {
      return t('Total');
    },
    estimatedPayoutStr() {
      return t('Estimated payout');
    },
    fromTodayStr() {
      return t('from today');
    },
    issueDateStr() {
      return t('Issue date');
    }
  },
  mounted() {
    if (!this.features) {
      this.$store.dispatch('DataHandler/getCards');
    }
  }
};
