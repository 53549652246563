export const cardNameToTestClass = cardName => {
  switch (cardName) {
    case 'PAYMENT_TURNAROUND':
      return 'tst-unpaid-invoices-card';
    case 'REJECTION_RATE':
      return 'tst-rejection-rate-card';
    case 'GO_LIVE_DATE':
      return 'tst-go-live-card';
    case 'LINK':
      return 'tst-manual-invoicing-card';
    default:
      return '';
  }
};
