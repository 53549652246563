import { UBLStatus } from '@/utils/ublConstants';
import { userEvents } from '../../utils/constants';

export default {
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  methods: {
    isStepRejected(step) {
      return (
        step.responseCode === UBLStatus.BusinessReject ||
        step.responseCode === UBLStatus.TechnicalReject
      );
    },
    isNextStepCompleted(steps, index) {
      index++;

      if (!steps[index]) {
        return false;
      }

      return steps[index].issueDate !== null;
    },
    activate() {
      let url = `${this.$store.getters['DataHandler/activateLink']}&cta=VIEW_FULL_DOCUMENT`;
      let cta = 'VIEW_FULL_DOCUMENT';
      if (this.document.documentUUID) {
        url += `&targetApp=Tradeshift.ConversationLG/view/${this.document.documentUUID}`;
      } else {
        url += `&targetApp=Tradeshift.DocumentManager`;
        cta = 'COMPLETE_YOUR_SETUP';
      }
      window.open(url, '_blank')?.focus();
      this.$pendo.track(userEvents.document_details_activate_click, { cta });
    }
  },
  computed: {
    supplierEnrichments() {
      return this.$store.getters['DataHandler/supplierEnrichments'];
    },
    documentStatusStr() {
      return t('Document status');
    },
    infoStr() {
      return t('Status messages are provided by { customer }', {
        customer: this.supplierEnrichments.customer
      });
    },
    buttonLabelStr() {
      return t('View full document');
    }
  }
};
