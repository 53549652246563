import { hasResponseCode, isPending } from '@/utils/helpers';
import { UBLStatus } from '@/utils/ublConstants';

export const addMissingUpdates = function (updatesArray) {
  const modifiedUpdatesArray = updatesArray.slice();

  if (isPending(updatesArray)) {
    const stepOptions = [
      {
        issueDate: null,
        responseCode: UBLStatus.BusinessDelivered
      },
      {
        issueDate: null,
        responseCode: UBLStatus.BusinessAccept
      },
      {
        issueDate: null,
        responseCode: UBLStatus.BusinessPaid
      }
    ];

    stepOptions.forEach(stepOption => {
      if (
        !hasResponseCode(modifiedUpdatesArray, stepOption.responseCode) &&
        // Prevent adding BusinessDelivered when BusinessMessage is present (equivalent)
        !(
          hasResponseCode(modifiedUpdatesArray, UBLStatus.BusinessMessage) &&
          stepOption.responseCode === UBLStatus.BusinessDelivered
        )
      ) {
        modifiedUpdatesArray.push(stepOption);
      }
    });
  }
  return modifiedUpdatesArray;
};
