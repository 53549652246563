import Vue from 'vue';
import i18n from '@tradeshift/i18n';
import { daysFromNow, daysToNow, formatDate, toDate } from './helpers';
import { UBLStatus } from '@/utils/ublConstants';

const { formatMonetaryAmount, formatNumber, CurrencyStyle } = i18n.numbers;

Vue.filter('toDate', value => {
  return toDate(value);
});

Vue.filter('simpleDate', date => {
  if (!date) {
    return t('No date');
  }

  // for example 13/01/2021 or 01/13/2021
  return i18n.dates.formatDate(new Date(date), { timeZone: 'UTC' });
});

Vue.filter('money', (value, currency) => {
  if (!value) {
    return t('No value');
  }

  if (!currency) {
    return formatNumber(value);
  } else {
    return formatMonetaryAmount(value, currency, CurrencyStyle.CODE);
  }
});

Vue.filter('docTypeName', value => {
  if (!value) return t('No type');
  const types = {
    invoice: {
      title: t('Invoice')
    },
    creditnote: {
      title: t('Credit note')
    }
  };
  return types[value] ? types[value].title : t('Unknown type');
});

Vue.filter('stepTitle', value => {
  const translations = {};
  translations[UBLStatus.BusinessReject] = t('Rejected');
  translations[UBLStatus.BusinessDelivered] = t('Received');
  translations[UBLStatus.BusinessAccept] = t('Accepted');
  translations[UBLStatus.BusinessPaid] = t('Paid');
  translations[UBLStatus.BusinessPending] = t('Pending');
  translations[UBLStatus.BusinessDisputed] = t('Disputed');
  translations[UBLStatus.BusinessReverted] = t('Reverted');
  translations[UBLStatus.BusinessOverdue] = t('Overdue');
  translations[UBLStatus.TechnicalReject] = t('Rejected');
  translations[UBLStatus.BusinessMessage] = t('Received');

  return translations[value] || value;
});

Vue.filter('stepTsStatus', value => {
  const iconNames = {};
  iconNames[UBLStatus.BusinessMessage] = 'default';
  iconNames[UBLStatus.BusinessDelivered] = 'default';
  iconNames[UBLStatus.BusinessAccept] = 'primary';
  iconNames[UBLStatus.BusinessPaid] = 'success';
  iconNames[UBLStatus.BusinessPending] = 'default';
  iconNames[UBLStatus.BusinessDisputed] = 'warning';
  iconNames[UBLStatus.BusinessReverted] = 'warning';
  iconNames[UBLStatus.BusinessOverdue] = 'error';
  iconNames[UBLStatus.BusinessReject] = 'error';
  iconNames[UBLStatus.TechnicalReject] = 'error';

  return iconNames[value] || '';
});

Vue.filter('payoutRange', document => {
  if (!document.payoutEarly || !document.payoutLate) return '';
  const daysFromNowEarly = daysFromNow(document.payoutEarly);
  const daysFromNowLate = daysFromNow(document.payoutLate);
  if (daysFromNowLate > daysFromNowEarly) {
    return t('Anticipated in { daysFromNowEarly }-{ daysFromNowLate } days', {
      daysFromNowEarly,
      daysFromNowLate
    });
  } else {
    const daysToNowLate = daysToNow(document.payoutLate);
    if (daysFromNowEarly || daysToNowLate === 0) {
      return daysFromNowEarly
        ? t(
            'Anticipated in {daysFromNowEarly,plural, one{one day} other{# days}}',
            {
              daysFromNowEarly
            }
          )
        : t('Anticipated today');
    } else {
      return t('{daysToNowLate,plural, one{one day} other{# days}} overdue', {
        daysToNowLate
      });
    }
  }
});

Vue.filter('payoutRangeDates', document => {
  if (
    document.payoutEarly &&
    document.payoutLate &&
    document.payoutEarly !== document.payoutLate
  )
    return `${toDate(document.payoutEarly)} - ${toDate(document.payoutLate)}`;
  if (document.payoutEarly === document.payoutLate)
    return toDate(document.payoutEarly);

  return '';
});

Vue.filter('payoutEarlyDate', document => {
  return formatDate(document.payoutEarly);
});

Vue.filter('payoutLateDate', document => {
  return formatDate(document.payoutLate);
});
