<template>
  <div class="icon-button" :class="{ padded: label }">
    <span class="icon-button-content">
      <img :src="iconPath" alt="button icon" />
      <span v-if="label">{{ label }}</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    iconPath: String,
    label: String
  }
};
</script>

<style lang="less">
@import '../../styles/vars';

.icon-button {
  display: inline-block;
  border-radius: var(--ts-unit-triple);
  cursor: pointer;

  &:hover {
    background: var(--ts-color-blue-lightest-lightest);
  }

  &-content {
    display: flex;
    align-items: center;
    color: var(--ts-color-blue);

    img {
      width: 24px;
      height: 24px;
    }

    span {
      margin-left: var(--ts-unit-half);
    }
  }
}

.padded {
  padding: var(--ts-unit-half) var(--ts-unit) var(--ts-unit-half);
}
</style>
