import i18n from '@tradeshift/i18n';

export const toDate = value => {
  if (!value) return '';
  return i18n.dates.formatDate(new Date(value), {
    timeZone: 'UTC',
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
};
