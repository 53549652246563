import DocumentList from '../DocumentList/DocumentList.vue';
import '@tradeshift/elements.tabs';
import '@tradeshift/elements.spinner';
import DashboardGanttChart from '@/components/DashboardGanttChart/DashboardGanttChart.vue';
import { tabs } from '@/utils/constants';

export default {
  data() {
    return {
      tabsStylesAppended: false,
      processing: false,
      currentTab: undefined,
      tabs,
      maxDocCount: 20 /* min is 10, for pagination to work */,
      isChartTabShowing: true,
      isChartTabAdded: false
    };
  },
  components: {
    DocumentList,
    DashboardGanttChart
  },
  methods: {
    documentsReady() {
      return !this.processing && this.hasDocuments();
    },
    noDocuments() {
      return !this.processing && !this.hasDocuments();
    },
    hasDocuments() {
      return (
        this.documentsPending.length > 0 ||
        this.documentsSettled.length > 0 ||
        this.documentsRejected.length > 0
      );
    },
    setCurrentTab() {
      if (this.documentsPending.length > 0) {
        this.currentTab = tabs.pending;
      } else if (this.documentsSettled.length > 0) {
        this.currentTab = tabs.settled;
      } else if (this.documentsRejected.length > 0) {
        this.currentTab = tabs.rejected;
      }
    },
    selectTab(e, tabName) {
      this.$nextTick(() => {
        if (e.target.selected) {
          this.currentTab = tabName;
          this.$store.dispatch('setCurrentTab', this.currentTab);
        }
      });
    },
    selectUpcomingPaymentsTab(e) {
      this.selectTab(e, tabs.upcoming);
      if (e.target.selected) {
        this.$refs.chart.initBars();
      }
    },
    removeTabOnMobile() {
      const isChartWidthAvailable = window.innerWidth > 600;
      if (isChartWidthAvailable !== this.isChartTabShowing) {
        this.tabsStylesAppended = false;
      }
      this.isChartTabShowing = isChartWidthAvailable;
      if (isChartWidthAvailable) {
        this.customizeTabs();
      }
    },
    openDocument(document) {
      this.$store.dispatch('DataHandler/setAsOpened', document);
      this.$router.push(
        `/document-tracker/${this.$store.getters['DataHandler/token']}/${document.invoiceId}`
      );
    },
    customizeTabs() {
      if (!this.$refs.tabs || this.tabsStylesAppended) {
        return;
      }

      this.$nextTick(() => {
        const style = document.createElement('style');
        style.innerHTML = `
            .tabs-wrapper {
              padding: 0 var(--ts-unit);
              overflow-x: auto;
              overflow-y: hidden;
            }
            .tabs-wrapper button {
              height: var(--ts-unit-triple);
              padding: var(--ts-unit) var(--ts-unit-half);
              position: relative;
            }
            .tabs-wrapper {
              font-style: normal;
            }
            .tabs-wrapper .badge {
              font-style: normal;
              padding: 2px;
            }
            .tabs-wrapper .badge::before {
              border: 2px solid white;
              border-radius: 50%;
              background: var(--ts-color-cobalt);
              position: absolute;
              top: var(--ts-unit-three-fourth);
              right: var(--ts-unit-quarter);
              width: 8px;
              height: 8px;
              color: white;
              font-size: 5px;
              line-height: 1.6;
            }
            .tabs-wrapper .badge.pendingUnopened::before {
              ${this.pendingUnopened ? 'content: "";' : ''}
            }
            .tabs-wrapper .badge.settledUnopened::before {
              ${this.settledUnopened ? 'content: "";' : ''}
            }
            .tabs-wrapper .badge.rejectedUnopened::before {
              ${this.rejectedUnopened ? 'content: "";' : ''}
            }
        `;

        this.$refs.tabs.shadowRoot.appendChild(style);
        this.tabsStylesAppended = true;
      });

      setTimeout(() => {
        const tabHeaders = this.$refs.tabs.shadowRoot.querySelectorAll(
          '.tabs-wrapper button'
        );
        tabHeaders.forEach(tabHeader => {
          const tabName =
            tabHeader.getElementsByTagName('ts-typography')[0].text;
          const badge = tabHeader.getElementsByClassName('badge')[0];
          switch (tabName) {
            case this.unpaidInvoicesStr:
              tabHeader.classList.add('tst-unpaid-invoices-tab');
              if (this.pendingUnopened) {
                badge.classList.add('pendingUnopened');
              }
              break;
            case this.paidInvoicesStr:
              tabHeader.classList.add('tst-paid-invoices-tab');
              if (this.settledUnopened) {
                badge.classList.add('settledUnopened');
              }
              break;
            case this.rejectedInvoicesStr:
              tabHeader.classList.add('tst-rejected-invoices-tab');
              if (this.rejectedUnopened) {
                badge.classList.add('rejectedUnopened');
              }
              break;
            case this.upcomingPaymentsStr:
              tabHeader.classList.add('tst-upcoming-payments-tab');
              if (this.pendingUnopened) {
                badge.classList.add('pendingUnopened');
              }
              break;
            default:
              return;
          }
        });
      });
    },
    documentsCounter(length) {
      return length > 99 ? '99+' : String(length);
    },
    documentsUnopenedCounter(documents) {
      const unopenedDocumentsCount = documents.filter(
        doc => !doc.opened
      ).length;
      return unopenedDocumentsCount
        ? this.documentsCounter(unopenedDocumentsCount)
        : '';
    },
    init() {
      const savedCurrentTab = this.$store.getters.currentTab;
      if (savedCurrentTab) {
        this.currentTab = savedCurrentTab;
      } else {
        this.setCurrentTab();
      }

      this.removeTabOnMobile();
      window.addEventListener('resize', this.removeTabOnMobile);
    }
  },
  computed: {
    documentsPending() {
      return this.$store.getters['DataHandler/supplierDocuments']({
        pending: true
      });
    },
    documentsSettled() {
      return this.$store.getters['DataHandler/supplierDocuments']({
        settled: true
      });
    },
    documentsRejected() {
      return this.$store.getters['DataHandler/supplierDocuments']({
        rejected: true
      });
    },
    documentsSortedByPayoutLate() {
      return this.documentsPending
        .filter(doc => doc.payoutEarly && doc.payoutLate)
        .sort((doc, nextDoc) => doc.payoutLate - nextDoc.payoutLate);
    },
    pendingUnopened() {
      return this.documentsUnopenedCounter(this.documentsPending);
    },
    settledUnopened() {
      return this.documentsUnopenedCounter(this.documentsSettled);
    },
    rejectedUnopened() {
      return this.documentsUnopenedCounter(this.documentsRejected);
    },
    features() {
      return this.$store.getters['DataHandler/features'];
    },
    isUpcomingPaymentsFeatureActive() {
      return (
        typeof this.features?.find(
          feature => feature.cardName === 'UPCOMING_PAYMENTS'
        ) !== 'undefined'
      );
    },
    showUpcomingPayments() {
      return (
        this.documentsSortedByPayoutLate.length > 0 &&
        this.isUpcomingPaymentsFeatureActive
      );
    },
    docNotNowStr() {
      return t('Documents are not available right now');
    },
    loadingStr() {
      return t('Loading');
    },
    unpaidInvoicesStr() {
      return t('Unpaid invoices');
    },
    paidInvoicesStr() {
      return t('Paid invoices');
    },
    rejectedInvoicesStr() {
      return t('Rejected invoices');
    },
    upcomingPaymentsStr() {
      return t('Upcoming payments');
    }
  },
  mounted() {
    if (!this.$store.state['DataHandler'].subscribed) {
      this.processing = true;
      this.$store.dispatch('DataHandler/getSupplier').finally(() => {
        this.processing = false;
        this.init();
      });
    } else {
      this.init();
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.removeTabOnMobile);
  },
  updated() {
    // Upcoming Payments feature is activated in the getCards request
    if (this.isChartTabAdded !== this.isUpcomingPaymentsFeatureActive) {
      this.tabsStylesAppended = false;
    }
    this.isChartTabAdded = this.isUpcomingPaymentsFeatureActive;
    this.customizeTabs();
  }
};
