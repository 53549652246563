export const UBLStatus = {
  BusinessReject: 'BusinessReject',
  BusinessDelivered: 'BusinessDelivered',
  BusinessAccept: 'BusinessAccept',
  BusinessPaid: 'BusinessPaid',
  BusinessPending: 'BusinessPending',
  BusinessDisputed: 'BusinessDisputed',
  BusinessReverted: 'BusinessReverted',
  BusinessOverdue: 'BusinessOverdue',
  TechnicalReject: 'TechnicalReject',
  BusinessMessage: 'BusinessMessage'
};
