import '@tradeshift/elements.button';
import '@tradeshift/elements.button-group';
import '@tradeshift/elements.modal';
import InsightCard from '@/components/InsightCards/InsightCard/InsightCard.vue';
import i18n from '@tradeshift/i18n';
import { cardNameToTitle } from '@/utils/helpers';
import { intervalToDuration } from 'date-fns';

export default {
  components: {
    InsightCard
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    mobile: {
      type: Boolean,
      required: false,
      default: false
    },
    isDialogOpen: {
      type: Boolean,
      required: false,
      default: false
    },
    closeDialog: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  methods: {
    goLivePhaseTitle() {
      switch (this.goLivePhaseObj.phase) {
        case 1:
          return t(
            'You have { months,plural, one{one month} other{# months}} and { days,plural, one{one day} other{# days}} to register',
            {
              months: this.goLivePhaseObj.months,
              days: this.goLivePhaseObj.days
            }
          );
        case 2:
        case 3:
          return t(
            'You have { days,plural, one{one day} other{# days}} to register',
            {
              days: this.goLivePhaseObj.days
            }
          );
        case 4:
          return t('The deadline to register has passed');
        default:
          return t('You have more than 3 months to register');
      }
    },
    goLivePhaseInfo() {
      const dateFormat = i18n.dates.formatDate(this.goLivePhaseObj.date, {
        timeZone: 'UTC',
        day: 'numeric',
        month: 'long'
      });
      const translationProp1 = {
        buyerName: this.$store.state['DataHandler'].companyName,
        date: dateFormat,
        goLiveLink: this.item.cardValues[1].value
      };
      const translationProp2 = {
        buyerName: this.$store.state['DataHandler'].companyName,
        date: dateFormat
      };
      const translationProp3 = {
        buyerName: this.$store.state['DataHandler'].companyName,
        goLiveLink: this.item.cardValues[1].value
      };

      switch (this.goLivePhaseObj.phase) {
        case 1:
          if (this.item.cardValues[1]) {
            return t(
              'You will need to complete registration and start invoicing {buyerName} via Tradeshift by <b>{date}</b>. Activate now, or <a href="{ goLiveLink }" target="_blank" rel="noopener noreferrer"  class="advertisementLink">learn more</a>.',
              translationProp1
            );
          } else {
            return t(
              'You will need to complete registration and start invoicing {buyerName} via Tradeshift by <b>{date}</b>. Activate now.',
              translationProp2
            );
          }
        case 2:
          if (this.item.cardValues[1]) {
            return t(
              '{buyerName} requires you to complete registration and start invoicing via Tradeshift by <b>{date}</b>. Activate now, or <a href="{ goLiveLink }" target="_blank" rel="noopener noreferrer"  class="advertisementLink">learn more</a>.',
              translationProp1
            );
          } else {
            return t(
              '{buyerName} requires you to complete registration and start invoicing via Tradeshift by <b>{date}</b>. Activate now.',
              translationProp2
            );
          }
        case 3:
          if (this.item.cardValues[1]) {
            return t(
              'Are you ready to start invoicing {buyerName} via <a href="{ goLiveLink }" target="_blank" rel="noopener noreferrer"  class="advertisementLink">Tradeshift</a>? Continue to receive your invoice status notifications and see all your documents from your Tradeshift account.',
              translationProp3
            );
          } else {
            return t(
              'Are you ready to start invoicing {buyerName} via Tradeshift? Continue to receive your invoice status notifications and see all your documents from your Tradeshift account.',
              {
                buyerName: this.$store.state['DataHandler'].companyName
              }
            );
          }
        case 4:
          if (this.item.cardValues[1]) {
            return t(
              'Going forward, {buyerName} expects that you invoice them using <a href="{ goLiveLink }" target="_blank" rel="noopener noreferrer"  class="advertisementLink">Tradeshift</a>. Sign up now to start sending invoices and continue receiving invoice status notifications.',
              translationProp3
            );
          } else {
            return t(
              'Going forward, {buyerName} expects that you invoice them using Tradeshift. Sign up now to start sending invoices and continue receiving invoice status notifications.',
              {
                buyerName: this.$store.state['DataHandler'].companyName
              }
            );
          }
        default:
          return '';
      }
    }
  },
  computed: {
    headerTitle() {
      return cardNameToTitle(this.item.cardName);
    },
    goLivePhaseObj() {
      if (this.item.cardName === 'GO_LIVE_DATE') {
        let phase = 0,
          days = 0,
          months = 0;
        const goLiveDate = new Date(this.item.cardValues[0].value);
        const todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);

        if (goLiveDate >= todayDate) {
          const duration = intervalToDuration({
            start: todayDate,
            end: goLiveDate
          });
          months = duration.months;
          days = duration.days;

          // date range is <= than 3 months
          if (months <= 3) {
            if (months >= 1) {
              phase = 1;
            } else if (days > 7) {
              phase = 2;
            } else if (days > 0) {
              phase = 3;
            } else {
              phase = 4;
            }
          }
        } else {
          phase = 4;
        }

        return { phase, days, months, date: goLiveDate };
      } else return {};
    },
    title() {
      switch (this.item.cardName) {
        case 'PAYMENT_TURNAROUND':
          return t(
            'You have { totalAmount } worth of invoices you could get paid today',
            {
              totalAmount: this.item.cardValue
            }
          );
        case 'REJECTION_RATE':
          return t('Your rejection rate is {percent}%', {
            percent: this.item.cardValue
          });
        case 'GO_LIVE_DATE':
          return this.goLivePhaseTitle();
        case 'LINK':
          return t('Speed up your invoicing with Tradeshift Link');
        default:
          return this.item.cardName;
      }
    },
    info() {
      switch (this.item.cardName) {
        case 'PAYMENT_TURNAROUND':
          return t(
            'With Tradeshift Cash you can reduce your payment turnaround and get paid instantly.'
          );
        case 'REJECTION_RATE':
          return t(
            'Issue your invoices via Tradeshift and get built-in guidance to minimize the risk of rejection.'
          );
        case 'GO_LIVE_DATE':
          return this.goLivePhaseInfo();
        case 'LINK':
          return t(
            'Email your PDF invoice and we convert it to {buyerName}’s format, saving you at least {time,plural, one{one hour} other{# hours}} this year.',
            {
              buyerName: this.$store.state['DataHandler'].companyName,
              time: this.item.cardValue
            }
          );
        default:
          return '';
      }
    },
    ctaLink() {
      switch (this.item.cardName) {
        case 'LINK':
          return 'https://tradeshift.com/supply-chain-solutions/link/';
        default:
          return `${this.$store.getters['DataHandler/activateLink']}&cta=${this.item.cardName}`;
      }
    },
    actionButtonLabel() {
      switch (this.item.cardName) {
        case 'PAYMENT_TURNAROUND':
          return t('Speed up your payments');
        case 'LINK':
          return t('Learn more');
        default:
          return t('Sign Up');
      }
    },
    isMoreInfoIconShown() {
      return ['REJECTION_RATE'].includes(this.item.cardName);
    },
    closeStr() {
      return t('Close');
    }
  },
  updated() {
    if (
      this.$refs.cardModal &&
      this.$refs.cardModal.shadowRoot &&
      !this.$refs.cardModal.shadowRoot.style
    ) {
      this.$nextTick(() => {
        const style = document.createElement('style');
        style.innerHTML =
          '.container.medium { height: 98vh; position: fixed; }';
        this.$refs.cardModal.shadowRoot.appendChild(style);
      });
    }
  }
};
