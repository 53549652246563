const isProductionEnv = () =>
  window.location.hostname === 'track.tradeshift.com';

const isSandboxEnv = () =>
  window.location.hostname === 'track.sandbox.tradeshift.com';

/* RISE stack */
const isTestingEnv = () =>
  window.location.hostname ===
  'ts-eu-west-1-test-615254691163-test-track-and-trace.s3-website-eu-west-1.amazonaws.com';

/* Demo | prod AWS account */
const isDemoEnv = () =>
  window.location.hostname ===
  'ts-eu-west-1-demo-300279173109-prod-track-and-trace.s3-website-eu-west-1.amazonaws.com';

export const targetEnvURL = isProductionEnv()
  ? 'https://go.tradeshift.com/'
  : isTestingEnv()
  ? `https://frontend.eu-west-1.test.${process.env.VUE_APP_STACK}.tradeshift.net/`
  : isDemoEnv()
  ? 'https://demo.tradeshift.com/'
  : 'https://sandbox.tradeshift.com/'; // test env also has sandbox as targetEnv

export const baseURL = isProductionEnv()
  ? 'https://api.tradeshift.com/tradeshift/rest/external'
  : isSandboxEnv()
  ? 'https://api-sandbox.tradeshift.com/tradeshift/rest/external'
  : isTestingEnv()
  ? `https://api-default.eu-west-1.test.${process.env.VUE_APP_STACK}.tradeshift.net/tradeshift/rest/external`
  : isDemoEnv()
  ? 'https://api-demo.tradeshift.com/tradeshift/rest/external'
  : 'http://localhost:8080'; // fallback to local development env

export const tabs = {
  pending: 'pending',
  settled: 'settled',
  rejected: 'rejected',
  upcoming: 'upcoming'
};

export const isIEUpTo11 = () =>
  window.navigator.userAgent.match(/(MSIE|Trident\/7.0)/);

export const userEvents = {
  document_details_activate_click: '[AO]document_details_activate_click',
  document_gantt_click: '[AO]document_gantt_click',
  document_list_click: '[AO]document_list_click',
  insight_open: '[AO]insight_open',
  main_activate_click: '[AO]main_activate_click',
  main_learn_more: '[AO]main_learn_more',
  userId_not_available: '[AO]userId_not_available'
};
