import { isIEUpTo11 } from '@/utils/constants';
import Button from '@/components/Button/Button.vue';

export default {
  components: {
    Button
  },
  computed: {
    browserImageName() {
      if (isIEUpTo11()) {
        return 'ie';
      }
      return null;
    },
    titleStr() {
      return t('Unsupported browser/version');
    },
    descriptionStr() {
      return t(
        'This application is not compatible with your browser/version. Please use one that is supported by our platform.'
      );
    },
    buttonStr() {
      return t('View supported browsers');
    }
  },
  created() {
    document.title = this.titleStr;
  }
};
