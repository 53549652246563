import '@tradeshift/elements.icon';
import Header from '../../components/Header/Header.vue';
import InsightCards from '../../components/InsightCards/InsightCards.vue';
import DocumentTrackerSteps from './DocumentTrackerSteps.vue';
import DocumentTrackerDetails from './DocumentTrackerDetails.vue';
import NotFound from '@/views/Errors/NotFound.vue';

export default {
  props: {
    token: {
      type: String,
      required: true
    },
    invoiceId: {
      type: String,
      required: true
    }
  },
  components: {
    Header,
    InsightCards,
    DocumentTrackerSteps,
    DocumentTrackerDetails,
    NotFound
  },
  computed: {
    invoiceIdHashtagProof() {
      return this.invoiceId ? this.invoiceId : this.$route.hash;
    },
    document() {
      return this.documents.find(
        doc => doc.invoiceId === this.invoiceIdHashtagProof
      );
    },
    documents() {
      return this.$store.getters['DataHandler/supplierDocuments']({});
    },
    currentDocuments() {
      return this.$store.getters['DataHandler/supplierDocuments']({
        pending: this.document?.isPending,
        settled: this.document?.isSettled,
        rejected: this.document?.isRejected
      }).slice(0, 20);
    },
    documentTypeLabel() {
      if (this.document?.isSettled) {
        return t('Paid invoices');
      } else if (this.document?.isPending) {
        return t('Unpaid invoices');
      } else if (this.document?.isRejected) {
        return t('Rejected invoices');
      } else {
        return t('Unpaid invoices');
      }
    },
    documentType() {
      if (this.document?.isSettled) {
        return 'settled';
      } else if (this.document?.isPending) {
        return 'pending';
      } else if (this.document?.isRejected) {
        return 'rejected';
      } else {
        return 'pending';
      }
    }
  },
  methods: {
    markDocAsOpened() {
      if (this.document) {
        this.$store.dispatch('DataHandler/setAsOpened', this.document);
      }
    },
    init() {
      const currentTab = this.$store.getters.currentTab;
      if (!currentTab) {
        this.$store.dispatch('setCurrentTab', this.documentType);
      }
      this.markDocAsOpened(); // when navigating to document directly
    }
  },
  mounted() {
    if (!this.$store.state['DataHandler'].subscribed) {
      this.$store.dispatch('DataHandler/getSupplier').then(this.init);
    } else {
      this.init();
    }
  }
};
