import Vue from 'vue';
import Vuex from 'vuex';

import UI from './ui';
import DataHandler from './datahandler';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    UI,
    DataHandler
  }
});
