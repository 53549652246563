import { userEvents } from '../../utils/constants';
import Button from '../Button/Button.vue';

export default {
  methods: {
    activateForFree() {
      this.$pendo.track(userEvents.main_activate_click, {
        from: 'COMPLETE_YOUR_SETUP'
      });
    }
  },
  components: {
    Button
  },
  computed: {
    supplierEnrichment() {
      return this.$store.getters['DataHandler/supplierEnrichments'];
    },
    buttonLabel() {
      return t('Complete your setup');
    },
    description() {
      if (this.supplierEnrichment.customer === '?') return '';
      return t(
        'With this free service from Tradeshift, you can track your invoices with { customer }, and get an estimate for when you should receive payments.',
        { customer: this.supplierEnrichment.customer }
      );
    }
  }
};
