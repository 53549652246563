import i18n from '@tradeshift/i18n';
import { cardNameToTitle, cardNameToTestClass } from '@/utils/helpers';

export default {
  props: {
    item: {
      cardName: String,
      value: String,
      cardValues: [
        {
          name: String,
          value: String
        }
      ]
    },
    selected: Boolean
  },
  computed: {
    title() {
      return cardNameToTitle(this.item.cardName);
    },
    subtitle() {
      switch (this.item.cardName) {
        case 'PAYMENT_TURNAROUND':
          // temporary, until backend is able to send the value
          if (this.documentsPending[0] && this.totalValue) {
            this.$store.dispatch(
              'DataHandler/setPaymentTurnAroundInsightValue',
              this.totalValue
            );
          } else {
            this.$store.dispatch('DataHandler/deleteCard', this.item.cardName);
          }

          return this.item.cardValue;
        case 'REJECTION_RATE':
          // temporary, until backend is able to send the value
          if (this.documentsRejected.length) {
            const rejectionRate = Math.round(
              (100 * this.documentsRejected.length) /
                (this.documentsRejected.length + this.documentsSettled.length)
            );

            this.$store.dispatch(
              'DataHandler/setRejectionRateInsightValue',
              rejectionRate
            );
          } else {
            this.$store.dispatch('DataHandler/deleteCard', this.item.cardName);
          }

          return t('{percent}%', {
            percent: this.item.cardValue
          });
        case 'GO_LIVE_DATE':
          return this.item.cardValues[0]
            ? i18n.dates.formatDate(new Date(this.item.cardValues[0].value), {
                timeZone: 'UTC',
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric'
              })
            : t('Not yet specified');
        case 'LINK':
          // temporary, until backend is able to send the value
          const timeSaved = Math.floor(
            (1 / 6) * this.documentsInLast12Months.length
          );
          if (timeSaved > 0 && this.documentsInLast12Months.length >= 100) {
            this.$store.dispatch('DataHandler/setLinkInsightValue', timeSaved);
          } else {
            this.$store.dispatch('DataHandler/deleteCard', this.item.cardName);
          }

          return t('{time,plural, one{one+ hour} other{#+ hours}}/year', {
            time: this.item.cardValue
          });
        default:
          return '';
      }
    },
    testClass() {
      return cardNameToTestClass(this.item.cardName);
    },
    // temporary, until backend is able to send the value
    documentsInLast12Months() {
      return this.$store.getters['DataHandler/supplierDocuments']({
        customFilter: 1
      });
    },
    // temporary, until backend is able to send the value
    documentsPending() {
      return this.$store.getters['DataHandler/supplierDocuments']({
        pending: true
      });
    },
    // temporary, until backend is able to send the value
    documentsSettled() {
      return this.$store.getters['DataHandler/supplierDocuments']({
        settled: true
      });
    },
    // temporary, until backend is able to send the value
    documentsRejected() {
      return this.$store.getters['DataHandler/supplierDocuments']({
        rejected: true
      });
    },
    // temporary, until backend is able to send the value
    totalValue() {
      return this.$options.filters.money(
        this.documentsPending.reduce(
          (result, document) => result + document.payableAmount,
          0
        ),
        this.documentsPending[0].documentCurrencyCode
      );
    }
  }
};
