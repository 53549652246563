<template>
  <a
    v-if="href"
    class="button"
    :class="type"
    :href="href"
    :target="target"
    rel="noopener"
    @click="$emit('click')"
  >
    <span class="text">
      <slot></slot>
    </span>
  </a>
  <button
    v-else
    class="button"
    :class="type"
    type="button"
    @click="$emit('click')"
  >
    <span class="text">
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String
    },
    href: String,
    target: String
  }
};
</script>

<style lang="less" scoped>
@import '../../styles/vars';

.button {
  display: inline-block;
  border: none;
  border-radius: 44px;
  padding: 10px 24px;
  transition: 1s background cubic-bezier(0.075, 0.82, 0.165, 1);
  background: rgba(10, 55, 240, 1); // IE support

  &:hover {
    cursor: pointer;
  }

  .text {
    white-space: nowrap;
    line-height: var(--ts-fontsize-xbig);
    font-size: var(--ts-fontsize-medium);
    font-weight: var(--ts-fontweight-bold);
    color: white; // IE support
  }

  &.primary {
    background: var(--ts-color-cobalt-dark);

    .text {
      color: var(--ts-color-white);
    }
  }

  &.secondary {
    background: var(--ts-color-white);

    .text {
      color: var(--ts-color-cobalt-dark);
    }
  }
}
</style>
