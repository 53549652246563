import ErrorView from './Error.vue';

export default {
  created() {
    document.title = t('Page not found');
  },
  computed: {
    title() {
      return t('Page not found');
    },
    description() {
      return t(
        'Sorry, the page you requested cannot be found. Please choose one of the options below, or use your browser’s back button to return to the previous page.'
      );
    }
  },
  components: {
    ErrorView
  }
};
