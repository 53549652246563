import '@tradeshift/elements.spinner';
import '@/utils/filters.js';
import NotFound from './views/Errors/NotFound.vue';
import { isIEUpTo11, userEvents } from '@/utils/constants';
import BrowserNotSupported from '@/views/BrowserNotSupported/BrowserNotSupported.vue';

export default {
  data() {
    return {
      isReady: false
    };
  },
  components: {
    NotFound,
    BrowserNotSupported
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
    notFound() {
      return this.$route.name === 'NotFound';
    },
    isUnsupportedBrowser() {
      return isIEUpTo11();
    },
    responseCode() {
      return this.$store.getters['DataHandler/responseCode'];
    },
    yourTokenIsExpiredStr() {
      return t('Your token is expired.');
    },
    yourTokenIsInvalidStr() {
      return t('Your token is invalid.');
    },
    serviceStr() {
      return t('Service temporarily unavailable.');
    },
    securityStr() {
      return t(
        "For the security of both you and your buyer, guest access to this tool through the email invitation is limited to 30 days. Your buyer's subscription with Tradeshift grants your company a free account with Tradeshift. Please activate your account to continue using this service."
      );
    },
    waitStr() {
      return t('Loading');
    }
  },
  mounted() {
    if (this.token && !this.$store.state['DataHandler'].subscribed) {
      this.$store.dispatch('DataHandler/setToken', this.token);
      this.$store.dispatch('DataHandler/getSupplier').finally(() => {
        this.isReady = true;
      });
    } else {
      this.isReady = true;
    }
  },
  created() {
    document.title = t('Tradeshift | Track your documents');
  }
};
