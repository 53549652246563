import '@tradeshift/elements.button';

export default {
  computed: {
    isExplanationShown() {
      return this.$store.getters.isExplanationShown;
    },
    paragraph1Str() {
      return t(
        'These calculated estimates are based on a number of factors, including:'
      );
    },
    listItem1Str() {
      return t('Your past transaction history with the buyer');
    },
    listItem2Str() {
      return t('The individual invoice values, such as total amount');
    },
    listItem3Str() {
      return t('Payment trends for the buyer, industry and location');
    },
    paragraph2Str() {
      return t(
        'This is a service provided by <a href="https://www.tradeshift.com">Tradeshift</a>. While we believe that the estimates are precise, we cannot guarantee remittance within the presented time ranges.'
      );
    },
    gotItStr() {
      return t('Got it');
    }
  },
  methods: {
    stopPropagation(e) {
      e.stopPropagation();
    }
  }
};
