var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.document)?_c('div',{staticClass:"steps"},[_c('div',{staticClass:"header"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.documentStatusStr))]),_c('ts-button',{staticClass:"tst-view-full-document",on:{"click":function($event){return _vm.activate()}}},[_vm._v(" "+_vm._s(_vm.buttonLabelStr)+" ")])],1),_c('div',{staticClass:"steps-wrapper"},_vm._l((_vm.document.statusUpdates),function(step,index){return _c('div',{key:'step' + index,staticClass:"step",class:{
        completed: step.issueDate !== null,
        reject: _vm.isStepRejected(step),
        nextCompleted: _vm.isNextStepCompleted(_vm.document.statusUpdates, index)
      }},[_c('div',{staticClass:"step-icon"},[_c('img',{attrs:{"src":`/images/${
            _vm.isStepRejected(step) ? 'rejected' : 'checked'
          }.svg`,"alt":"step icon"}})]),_c('div',{staticClass:"step-details"},[_c('div',{staticClass:"step-details-main"},[_c('div',{staticClass:"step-title"},[_vm._v(_vm._s(_vm._f("stepTitle")(step.responseCode)))]),(step.issueDate !== null)?_c('div',{staticClass:"step-date"},[_vm._v(" "+_vm._s(_vm._f("toDate")(step.issueDate))+" ")]):_vm._e()]),(step.responseDescription)?_c('div',{staticClass:"step-details-explanation"},[_vm._v(" \""+_vm._s(step.responseDescription)+"\" ")]):_vm._e()])])}),0),_c('div',{staticClass:"info"},[_vm._v(_vm._s(_vm.infoStr))])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }