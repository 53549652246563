import '@tradeshift/elements.pager';
import '@tradeshift/elements.status';
import '@tradeshift/elements.tooltip';
import { userEvents } from '../../utils/constants';

export default {
  data() {
    return {
      pageSize: this.itemsPerPage,
      currentDocuments: this.documents.slice(0, this.itemsPerPage),
      activePage: 1
    };
  },
  props: {
    documents: {
      type: Array,
      default: () => [],
      required: true
    },
    openDocument: {
      type: Function,
      required: true
    },
    type: {
      type: String,
      default: null,
      required: true
    },
    title: {
      type: String,
      default: null,
      required: true
    },
    itemsPerPage: {
      type: Number,
      required: true
    }
  },
  methods: {
    latestUpdateStatus(latestUpdate) {
      return this.$options.filters.stepTitle(latestUpdate.responseCode);
    },
    updateToTsStatus(latestUpdate) {
      return this.$options.filters.stepTsStatus(latestUpdate.responseCode);
    },
    onPageChange(event) {
      const activePage = event.detail.newVal;
      const startIndex = (activePage - 1) * this.pageSize;

      this.activePage = activePage;
      this.currentDocuments = this.documents.slice(
        startIndex,
        startIndex + this.pageSize
      );
    },
    onPerPageChange(event) {
      this.pageSize = event.detail.per_page;
      this.activePage = 1;
      this.currentDocuments = this.documents.slice(0, this.pageSize);
    },
    openDocumentOnClick(document) {
      this.$pendo.track(userEvents.document_list_click, {
        documentType: this.type
      });
      this.openDocument(document);
    }
  },
  computed: {
    isPagerShown() {
      return this.documents.length > this.itemsPerPage;
    },
    totalPages() {
      return Math.ceil(this.documents.length / this.pageSize);
    },
    // TODO: Update when backend start supporting multicurrencies
    currencyCode() {
      return this.documents[0].documentCurrencyCode;
    },
    totalValue() {
      return this.documents.reduce(
        (result, document) => result + document.payableAmount,
        0
      );
    },
    // Labels
    invoiceNumberLabel() {
      return t('Invoice number');
    },
    issuedOnLabel() {
      return t('Issued on');
    },
    currencyLabel() {
      return t('Currency');
    },
    amountLabel() {
      return t('Amount');
    },
    totalValueLabel() {
      return t('Total');
    },
    statusLabel() {
      return t('Status');
    },
    totalValueTooltip() {
      return t('This is the total amount of {invoicesType}', {
        invoicesType: this.title.toLowerCase()
      });
    }
  }
};
