import { UBLStatus } from '@/utils/ublConstants';

export const computePaymentTime = function (updates, receivedIssueDate) {
  const businessPaid = updates.find(
    update =>
      update.responseCode === UBLStatus.BusinessPaid &&
      update.issueDate !== null
  );
  if (receivedIssueDate && businessPaid) {
    const timestampDelta =
      Date.parse(businessPaid.issueDate) - Date.parse(receivedIssueDate);
    return Math.round(timestampDelta / (1000 * 60 * 60 * 24));
  }
  return null;
};
